
















































import LazyHydrate from 'vue-lazy-hydration';
import Cookies from 'cookie-universal';
import {
  useRoute,
  defineComponent,
  onMounted,
  ref,
  watch,
  onBeforeMount,
} from '@nuxtjs/composition-api';
import { SfButton, SfModal, SfLink } from '@storefront-ui/vue';
import { useUiState } from '~/composables';
import useCart from '~/modules/checkout/composables/useCart';
import AppHeader from '~/components/Header/AppHeader.vue';
import AppHeaderCheckout from '~/components/Header/AppHeaderCheckout.vue';
import IconSprite from '~/components/General/IconSprite.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import AppHeaderMobile from '@/components/Header/AppHeaderMobile.vue';
import { useLanguageStore } from '~/components/Header/StoreSwitcher/storeLanguage'

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    AppHeaderMobile,
    SfButton,
    SfModal,
    SfLink,
    LoadWhenVisible,
    LazyHydrate,
    AppHeader,
    AppHeaderCheckout,
    IconSprite,
    LoginModal: () =>
      import(/* webpackPrefetch: true */ '../components/General/Modal/LoginModal.vue'),
    Notification: () =>
      import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
    SelectedLanguageModal: () =>
      import(/* webpackPrefetch: true */ '../components/General/Modal/SelectedLanguageModal.vue'),
  },

  setup() {
    function silentErrorHandler() {
      return true;
    }

    window.onerror = silentErrorHandler;
    const cookies = Cookies();
    const route = useRoute();
    const { isLoginModalOpen, toggleLoginModal } = useUiState();
    const { load: loadCart } = useCart();
    const topbarDisabled = ref(false);
    const isCookiesAccepted = ref(false);
    isCookiesAccepted.value = cookies.get('vsf-accept-cookies');
    const openSelectLanguageModal = ref<Boolean>(false);
    const languageStore = useLanguageStore();

    function checkPath() {
      topbarDisabled.value = !!(
        route.value.fullPath.includes('user-account') ||
        route.value.fullPath.includes('shipping') ||
        route.value.fullPath.includes('billing') ||
        route.value.fullPath.includes('payment')
      );
    }

    const switchHeader = () => {
      topbarDisabled.value = !topbarDisabled.value;
    };

    const dateNow = new Date();
    function add30DaysToDate(date, days) {
      var Date30 = new Date(date);
      Date30.setDate(Date30.getDate() + days);
      return Date30;
    }

    const acceptCookie = () => {
      cookies.set('vsf-accept-cookies', true, {
        expires: add30DaysToDate(dateNow, 30),
      });
      isCookiesAccepted.value = true;
    };

    onBeforeMount(async () => {
      await setlanguageStore();
    });

    watch(route, () => {
      checkPath();
      setlanguageStore();
    });

    const setlanguageStore = () => {
      const newLanguage = route.value.fullPath.includes('fr') ? 'fr' : 'en';
      languageStore.setLanguage(newLanguage);
    };

    onMounted(async () => {
      await loadCart();
      checkPath();
      console.clear();
      console.log = () => {};
      console.debug = () => {};
      console.info = () => {};
      console.warn = () => {};
    });

    const openStoreSwitcherModal = () => {
      openSelectLanguageModal.value = true
      document.body.style.overflow = 'hidden';
    }
    const closeSelectedLanguage = () => {
      openSelectLanguageModal.value = false
      document.body.style.overflow = '';
    }

    const closeModal = () => {
    toggleLoginModal();
  };

    return {
      isLoginModalOpen,
      isCookiesAccepted,
      acceptCookie,
      route,
      checkPath,
      topbarDisabled,
      switchHeader,
      openStoreSwitcherModal,
      closeSelectedLanguage,
      openSelectLanguageModal,
      closeModal,
    };
  },
});
